import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";

const Page = props => (
  <tr>
    <td>
      <Link to={"/account/"+props.account+"/page/"+props.page._id}>{props.page.url}</Link>
    </td>
    <td>
      {props.warningCount}
    </td>
    <td className="d-none d-lg-table-cell">
      {props.warnings}
    </td>
  </tr>
)

class AccountPages extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];
    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      accountname: "",
      user: "",
      accounturl: "",
      robots: "",
      pages: [],
      role: role,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    }
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("accounts:view")) {
      const config = await this.config();

      axios.get(process.env.REACT_APP_API + '/account/pages/' + this.props.match.params.id, config)
        .then(response => {
          this.setState({
            accountname: response.data.accountname,
            user: response.data.user,
            accounturl: response.data.accounturl,
            pages: response.data.pages,
            crawling: response.data.crawling,
            robots: response.data.robots,
            isReady: true
          })
        })
        .catch(err => console.log(err))
        .then(response => {
          document.title = this.state.accountname + " - searchboss";
        })
        .catch(function (error) {
          console.log(error);
        })
    } else {
      return null;
    }
  }

  pagesList() {
    return this.state.pages.map(page => {
        const warnings = [];

        if (page.h1count === 0) {
          warnings.push("No H1's.")
        }
        if (page.h1count >= 2) {
          warnings.push("Two or more H1's.")
        }
        if (page.h2count === 0) {
          warnings.push("No H2's.")
        }
        if (page.h3count <= 1) {
          warnings.push("One or less H3's.")
        }
        if (page.h4count <= 1) {
          warnings.push("One or less H4's.")
        }
        if (page.titleLength === undefined) {
          warnings.push("Title tag missing.")
        }
        if (page.titleLength <= 40) {
          warnings.push("Title tag too short.")
        }
        if (page.titleError === true) {
          warnings.push("Multiple title tags.")
        }
        if (page.metaDesLen === undefined) {
          warnings.push("Missing meta description.")
        }
        if (page.metaDesLen <= 50) {
          warnings.push("Meta description too short.")
        }
        if (page.metaDesLen >= 200) {
          warnings.push("Meta description too long.")
        }
        if (page.statusCode !== 200) {
          warnings.push("Status code " + page.statusCode + " found.")
        }

        if (page.robots.some(robot => robot.includes('noindex'))) {
          warnings.push("Noindexed via meta tag.")
        }

        if (page.robots.some(robot => robot.includes('nofollow'))) {
          warnings.push("Nofollowed via meta tag.")
        }

        const warningCount = warnings.length;
        var warningDetails = "";

        if (warningCount === 0) {
          warningDetails = "No warnings.";
        } else {
          warningDetails = warnings.join(" ");
        }

        return <Page account={this.props.match.params.id} page={page} warningCount={warningCount} warnings={warningDetails} key={page._id}/>;
    })
  }

  crawling() {
    if (this.state.crawling === true) {
      return (<h4>Crawl currently in progress.</h4>)
    } else {
      return null
    }
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    return (
      <Can
        role={this.state.role}
        perform="accounts:view"
        yes={() => (
          <div>
            <div className="row">
              <div className="col-sm-7">
                <h1>{this.state.accountname}</h1>
              </div>
              <div className="col-sm-5 text-right">
                { this.crawling() }
              </div>
            </div>
            <div className="row">
              <div className="col-sm-7">
                <a href={ this.state.accounturl } target="_blank" rel="noreferrer">{ this.state.accounturl }</a>
              </div>
              <div className="col-sm-5 text-right">
                <p><strong>User:</strong> {this.state.user}</p>
              </div>
            </div>
            <div className="row tableRow">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>URL</th>
                    <th>Warnings</th>
                    <th className="d-none d-lg-table-cell">Details</th>
                  </tr>
                </thead>
                <tbody>
                  { this.pagesList() }
                </tbody>
              </table>
            </div>
            <div className="row headingRow">
              <div className="col-sm-12">
                <h2>Robots.txt File</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <code className="new-line">{this.state.robots}</code>
              </div>
            </div>
          </div>
        )}
        no={() => (<div><h1>WHOOPS!</h1><h2>This account level does not have permission to view account pages.</h2></div>)}
      />
    )
  }
}

export default withAuth0(AccountPages);
