import React, { Component } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";

class EditDomain extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];

    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      users: [],
      role: role,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    }
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    document.title = "edit boost domain - searchboss";

    if (this.state.staticRules.includes("accounts:add")) {
      const config = await this.config();

      axios.get(process.env.REACT_APP_API + '/boost/domainEdit/' + this.props.match.params.id, config)
        .then(response => {
          this.setState({
            domain: response.data.domain,
            industry: response.data.industry,
            active: response.data.active,
            authUser: response.data.authUser,
            authKey: response.data.authKey,
            isReady: true
          })
        })
        .catch(err => console.log(err))
        .then(response => {
          document.title = "edit boost domain - " + this.state.domain + " - searchboss";
        })
        .catch(function (error) {
          console.log(error);
        })
    } else {
      return null;
    }
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    // initialize Yup validation
    const editDomainSchema = Yup.object().shape({
      domain: Yup.string().required("URL is required."),
      authUser: Yup.string().required("Auth username is required."),
      authKey: Yup.string().required("Auth key is required.")
    });

    const initialValues = {
      domain: this.state.domain,
      industry: this.state.industry,
      active: this.state.active,
      authUser: this.state.authUser,
      authKey: this.state.authKey
    };

    return (
      <Can
        role={this.state.role}
        perform="boost:domainAdd"
        yes={() => (
          <Formik
            initialValues={initialValues}
            validationSchema={editDomainSchema}
            onSubmit={async (values) => {
                const config = await this.config();
                axios.post(process.env.REACT_APP_API + '/boost/domainUpdate/' + this.props.match.params.id, values, config)
                  .then(res => alert(res.data))
                  .catch(err => alert(err.message))
            }}

          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <div>
                  <h3>Edit Boost Domain</h3>
                  <Form>
                    <div className="form-group">
                    <label htmlFor="accountname">Boost Domain</label>
                    <Field
                      type="text"
                      name="domain"
                      id="domain"
                      className={
                        errors.domain && touched.domain ? "input-error" : null, "form-control"
                      }
                    />
                    <ErrorMessage name="domain" component="span" className="error" />
                    </div>
                    <div className="form-group">
                    <label htmlFor="industry">Industry</label>
                      <Field
                        as="select"
                        name="industry"
                        id="industry"
                        className={
                          errors.industry && touched.industry ? "input-error" : null, "form-control"
                        }
                      >
                      { <option key="Cannabis" value="Cannabis">Cannabis</option> }
                      { <option key="Home Services" value="Home Services">Home Services</option> }
                      { <option key="Other" value="Other">Other</option> }
                      </Field>
                      <ErrorMessage name="industry" component="span" className="error" />
                    </div>
                    <div className="form-group">
                    <label htmlFor="active">Active</label>
                      <Field
                        as="select"
                        name="active"
                        id="active"
                        className={
                          errors.active && touched.active ? "input-error" : null, "form-control"
                        }
                      >
                      { <option key="true" value="true">True</option> }
                      { <option key="false" value="false">False</option> }
                      </Field>
                      <ErrorMessage name="active" component="span" className="error" />
                    </div>
                    <div className="form-group">
                    <label htmlFor="authUser">Auth User</label>
                      <Field
                        type="string"
                        name="authUser"
                        id="authUser"
                        className={
                          errors.authUser && touched.authUser ? "input-error" : null, "form-control"
                        }
                      />
                      <ErrorMessage name="authUser" component="span" className="error" />
                    </div>
                    <div className="form-group">
                    <label htmlFor="authUser">Auth Key</label>
                      <Field
                        type="string"
                        name="authKey"
                        id="authKey"
                        className={
                          errors.authKey && touched.authKey ? "input-error" : null, "form-control"
                        }
                      />
                      <ErrorMessage name="authKey" component="span" className="error" />
                    </div>
                    <button
                      type="submit"
                      className={!(dirty && isValid) ? "disabled-btn" : ""}
                      disabled={!dirty && isValid}
                    >
                      Submit
                    </button>
                  </Form>
                </div>
              );
            }}
          </Formik>
        )}
        no={() => (<div><h1>Whoops!</h1><h2>Your access level cannot add a boost domain. Sorry!</h2></div>)}
        />
    )
  }
}

export default withAuth0(EditDomain);
