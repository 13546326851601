import React from "react";
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

const ProfileButton = () => {
  const { isAuthenticated } = useAuth0();
  const { user } = useAuth0();

  if (isAuthenticated) {
    const { picture } = user;

    return (
        <Link to="/profile/" className="w-25">
          <img src={picture} alt="Profile" className="rounded-circle img-fluid profile-picture float-right" />
        </Link>
    )
  } else {
    return null;
  }
};

export default ProfileButton;
