import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";

const Domain = props => (
  <tr>
    <td className="d-none d-table-cell"><a href={props.domain.domain} target="_blank">{props.domain.domain}</a></td>
    <td className="d-none d-lg-table-cell">{props.domain.industry}</td>
    <td className="d-none d-lg-table-cell">{props.domain.active}</td>
    <td className="d-none d-lg-table-cell">{props.domain.createdAt}</td>
    <td className="d-none d-lg-table-cell">{props.domain.indexedPages} / <a href={ "https://www.google.com/search?q=site:" + props.domain.domain} target="_blank">check</a></td>
    <td className="table-buttons">
      <Can
        role={props.role}
        perform="boost:domainEdit"
        yes={() => (<Link className="btn btn-primary" to={"/boost/"+props.domain._id+"/edit/"}>edit</Link>)}
        no={() => (null)}
      />
      <Can
        role={props.role}
        perform="boost:domainDelete"
        yes={() => (<button className="btn btn-primary" onClick={() => { props.deleteDomain(props.domain._id)}}>delete</button>)}
        no={() => (null)}
      />
    </td>
  </tr>
)

class BoostHome extends Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.deleteDomain = this.deleteDomain.bind(this);
    this.domainAudit = this.domainAudit.bind(this);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];

    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      domains: [],
      role: role,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    };
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    document.title = "boost home - searchboss";

    if (this.state.staticRules.includes("accounts:boost")) {
      const config = await this.config();
      const role = this.state.role;
      const user = this.state.user;

      axios.get(process.env.REACT_APP_API + '/boost/domainList', config)
        .then(response => {
          this.setState({ domains:response.data, isReady: true });
        })
        .catch(err => console.log(err))
    } else {
      alert("This access level does not have permission to view boost domains. Please contact your administrator.")
    }
  }

  async deleteDomain(id) {
    if (this.state.staticRules.includes("boost:domainDelete")) {
      const config = await this.config();
      const data = null;

      axios.post(process.env.REACT_APP_API + '/boost/domainDelete/' + id, data, config)
        .then(
          response => {
            console.log(response.data);

            this.setState({
              domains: this.state.domains.filter(el => el._id !== id)
            })
        })
        .catch(err => alert(err.response.data.message))
    } else {
      alert("This access level does not have permission to delete boost domains. Please contact your administrator.")
    }
  }

  async domainAudit() {
    if (this.state.staticRules.includes("boost:indexAudit")) {
      const config = await this.config();
      const data = null;

      alert("Domain index audit started.");

      axios.post(process.env.REACT_APP_API + '/boost/domainAudit/', data, config)
        .then(
          response => {
            console.log(response.data);
        })
        .catch(err => alert(err.response.data.message))
    } else {
      alert("This access level does not have permission to delete run the index audit. Please contact your administrator.")
    }
  }

  domainList(role) {
    const domainsList = this.state.domains;

    return domainsList.map(currentdomain => {
      return <Domain domain={currentdomain} deleteDomain={this.deleteDomain} role={role} key={currentdomain._id}/>;
    })
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    const cannabisDomains = this.state.domains.filter((domain => domain.industry === "Cannabis"))
    const homeServicesDomains = this.state.domains.filter((domain => domain.industry === "Home Services"))

    const activeCannabisDomains = cannabisDomains.filter((domain => domain.active === "true"))
    const activeHomeServicesDomains = homeServicesDomains.filter((domain => domain.active === "true"))

    return (
      <div>
        <div className="row mb-2">
          <div className="col-lg-7">
              <h3>Boost Domains</h3>
              <b>Cannabis Domains:</b> {activeCannabisDomains.length} <small>active</small> / {cannabisDomains.length} <small>total</small><br />
              <b>Home Services Domains:</b> {activeHomeServicesDomains.length} <small>active</small> / {homeServicesDomains.length} <small>total</small>
          </div>
          <div className="col-lg-5 text-lg-right">
              <button onClick={this.domainAudit}>Run Domain Index Audit</button>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th>Domain</th>
                <th className="d-none d-lg-table-cell">Industry</th>
                <th className="d-none d-lg-table-cell">Active</th>
                <th className="d-none d-lg-table-cell">Creation Date</th>
                <th className="d-none d-lg-table-cell">Indexed Pages</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              { this.domainList(this.state.role) }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default withAuth0(BoostHome);
