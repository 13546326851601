import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";

class AccountPage extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];
    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      accountname: "",
      accounturl: "",
      page: [],
      role: role,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    }
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("page:view")) {
      const config = await this.config();

      axios.get(process.env.REACT_APP_API+'/account/'+this.props.match.params.account+'/page/'+this.props.match.params.page, config)
        .then(response => {
          this.setState({
            accountname: response.data.accountname,
            user: response.data.user,
            accounturl: response.data.accounturl,
            page: response.data.pages[0],
            accountid: this.props.match.params.account,
            isReady: true
          })
        })
        .catch(err => console.log(err))
        .then(response => {
          return document.title = this.state.page.url + " - searchboss";
        })
        .catch(err => console.log(err))
      } else {
        return null;
      }
  }

  pageUrl() {
    return this.state.page.url
  }

  pageTitle() {
    return this.state.page.title
  }

  pageTitleError() {
    if (this.state.page.titleError === true) {
      return "Multiple Title Tags Found"
    }
  }

  pageTitleLen() {
    if (this.state.page.titleLength < 40) {
      return (<strong className="text-danger">{this.state.page.titleLength}</strong>);
    } else if (this.state.page.titleLength === undefined) {
      return (<strong className="text-danger">0</strong>);
    }  else {
      return this.state.page.titleLength;
    }
  }

  pageMetaDes() {
    return this.state.page.metaDes
  }

  pageMetaDesLen() {
    if (this.state.page.metaDesLen < 50 || this.state.page.metaDesLen > 200) {
      return (<strong className="text-danger">{this.state.page.metaDesLen}</strong>);
    } else if (this.state.page.metaDesLen === undefined) {
      return (<strong className="text-danger">0</strong>);
    } else {
      return this.state.page.metaDesLen;
    }
  }

  pageContentLength() {
    if ( this.state.page.contentLength < 750 ) {
      return (<strong className="text-danger">{this.state.page.contentLength}</strong>);
    } else if ( this.state.page.contentLength === undefined || this.state.page.contentLength === 0 ) {
      return (<strong className="text-danger">No content found.</strong>);
    } else {
      return this.state.page.contentLength;
    }
  }

  pageRobots() {
    if (this.state.isReady === true) {
      return this.state.page.robots.map(robot => {
        return (<div key={robot}>{robot}</div>)
      })
    }
  }

  pageStatusCode() {
    return this.state.page.statusCode
  }

  pageh1s() {
    if (this.state.isReady === true) {
      return this.state.page.h1s.map(h1 => {
        return (<div key={h1}>{h1}</div>)
      })
    }
  }

  pageh2s() {
    if (this.state.isReady === true) {
      return this.state.page.h2s.map(h2 => {
        return (<div key={h2}>{h2}</div>)
      })
    }
  }

  pageh3s() {
    if (this.state.isReady === true) {
      return this.state.page.h3s.map(h3 => {
        return (<div key={h3}>{h3}</div>)
      })
    }
  }

  pageh4s() {
    if (this.state.isReady === true) {
      return this.state.page.h4s.map(h4 => {
        return (<div key={h4}>{h4}</div>)
      })
    }
  }

  pageh5s() {
    if (this.state.isReady === true) {
      return this.state.page.h5s.map(h5 => {
        return (<div key={h5}>{h5}</div>)
      })
    }
  }

  pageh6s() {
    if (this.state.isReady === true) {
      return this.state.page.h6s.map(h6 => {
        return (<div key={h6}>{h6}</div>)
      })
    }
  }

  pageh1count() {
    if (this.state.page.h1count !== 1) {
      return (<td className="table-danger">{this.state.page.h1count}</td>)
    } else {
      return (<td>{this.state.page.h1count}</td>)
    }
  }

  pageh2count() {
    if (this.state.page.h2count < 1) {
      return (<td className="table-danger">{this.state.page.h2count}</td>)
    } else {
      return (<td>{this.state.page.h2count}</td>)
    }
  }

  pageh3count() {
    if (this.state.page.h3count < 2) {
      return (<td className="table-warning">{this.state.page.h3count}</td>)
    } else {
      return (<td>{this.state.page.h3count}</td>)
    }
  }

  pageh4count() {
    if (this.state.page.h4count < 2) {
      return (<td className="table-warning">{this.state.page.h4count}</td>)
    } else {
      return (<td>{this.state.page.h4count}</td>)
    }
  }

  pageh5count() {
    if (this.state.page.h5count < 2) {
      return (<td className="table-warning">{this.state.page.h5count}</td>)
    } else {
      return (<td>{this.state.page.h5count}</td>)
    }
  }

  pageh6count() {
    if (this.state.page.h6count < 2) {
      return (<td className="table-warning">{this.state.page.h6count}</td>)
    } else {
      return (<td>{this.state.page.h6count}</td>)
    }
  }

  pageLinkCount() {
    return this.state.page.linkCount
  }

  pageUniqueLinkCount() {
    return this.state.page.uniqueLinkCount
  }

  pageLinks() {
    if (this.state.isReady === true) {
      return this.state.page.linkUrls.map(link => {
        return (<li className="list-group-item" key={link}>{link}</li>)
      })
    }
  }

    render() {
      if (this.state.isReady === false) {
        return <Loading />;
      }

      return (
        <Can
          role={this.state.role}
          perform="page:view"
          yes={() => (
            <div className="accountPage">
              <div className="row">
                <div className="col-sm-12">
                  <h1>{this.state.accountname}</h1>
                </div>
                <div className="col-sm-8">
                  <a href={ this.pageUrl() } target="_blank" rel="noreferrer">{ this.pageUrl() }</a>
                </div>
                <div className="col-sm-4 text-right">
                  <Link to={"/account/"+this.state.accountid+"/pages/"}>back to account page</Link>
                </div>
              </div>
              <div className="row headingRow">
                <div className="col-sm-12">
                  <h2>Page & Meta Information</h2>
                </div>
              </div>
              <div className="pagemetainfo">
                <div className="row">
                  <div className="col-sm-5">
                    <h4>Title</h4>
                  </div>
                  <div className="col-sm-7">
                    { this.pageTitle() }
                    <span className="text-danger d-block"><b>{ this.pageTitleError() }</b></span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5">
                    <h4>Title Length</h4>
                  </div>
                  <div className="col-sm-7">
                    { this.pageTitleLen() }
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5">
                    <h4>Meta Description</h4>
                  </div>
                  <div className="col-sm-7">
                    { this.pageMetaDes() }
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5">
                    <h4>Meta Description Length</h4>
                  </div>
                  <div className="col-sm-7">
                    { this.pageMetaDesLen() }
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5">
                    <h4>Content Length</h4>
                  </div>
                  <div className="col-sm-7">
                    { this.pageContentLength() }
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5">
                    <h4>Robots</h4>
                  </div>
                  <div className="col-sm-7">
                    { this.pageRobots() }
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5">
                    <h4>Status Code</h4>
                  </div>
                  <div className="col-sm-7">
                    { this.pageStatusCode() }
                  </div>
                </div>
              </div>
              <div className="row headingRow">
                <div className="col-sm-12">
                  <h2>Content Information</h2>
                  <h4>Heading Content</h4>
                </div>
              </div>
              <div className="headinginfo">
                <div className="row">
                  <div className="col-sm-5 font-weight-bold">
                    H1 Tags
                  </div>
                  <div className="col-sm-7">
                    { this.pageh1s() }
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5 font-weight-bold">
                    H2 Tags
                  </div>
                  <div className="col-sm-7">
                    { this.pageh2s() }
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5 font-weight-bold">
                    H3 Tags
                  </div>
                  <div className="col-sm-7">
                    { this.pageh3s() }
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5 font-weight-bold">
                    H4 Tags
                  </div>
                  <div className="col-sm-7">
                    { this.pageh4s() }
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5 font-weight-bold">
                    H5 Tags
                  </div>
                  <div className="col-sm-7">
                    { this.pageh5s() }
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5 font-weight-bold">
                    H6 Tags
                  </div>
                  <div className="col-sm-7">
                    { this.pageh6s() }
                  </div>
                </div>
              </div>
              <div className="row headingRow">
                <div className="col-sm-12">
                  <h4>Heading Counts</h4>
                </div>
              </div>
              <div className="row tableRow">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th>H1</th>
                      <th>H2</th>
                      <th>H3</th>
                      <th>H4</th>
                      <th>H5</th>
                      <th>H6</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      { this.pageh1count() }
                      { this.pageh2count() }
                      { this.pageh3count() }
                      { this.pageh4count() }
                      { this.pageh5count() }
                      { this.pageh6count() }
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="row headingRow">
                <div className="col-sm-12">
                  <h2>Linking Information</h2>
                </div>
              </div>
              <div className="row tableRow">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th>Total Links</th>
                      <th>Unique Links</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{ this.pageLinkCount() }</td>
                      <td>{ this.pageUniqueLinkCount() }</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="row headingRow">
                <div className="col-sm-12">
                  <h4>Unique Links</h4>
                </div>
              </div>
              <div className="row tableRow">
                <div className="col-sm-12 tableRow">
                  <ul className="list-group">
                    { this.pageLinks() }
                  </ul>
                </div>
              </div>
            </div>
          )}
        no={() => (<div><h1>Whoops!</h1><h2>It looks like you can't view this page.</h2></div>)}
      />
      )
    }
  }

export default withAuth0(AccountPage);
