import React, { Component } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";
import roles from "../rbac-roles";

class CreateUser extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];

    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      nickname: "",
      email: "",
      role: role,
      roles: roles,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    }
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    this.setState({ config: config });

    return config;
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("users:add")) {
      const config = await this.config();
      document.title = "create user - searchboss";
      this.setState({isReady: true})
    } else {
      return null;
    }
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    // initialize Yup validation
    const createUserSchema = Yup.object().shape({
      nickname: Yup.string().required("Username is required."),
      email: Yup.string().email("Must be a valid email address").required("Email is required."),
      role: Yup.string().required("Role is required.")
    });

    const initialValues = {
      nickname: "",
      email: "",
      role: "strategist"
    };

    return (
      <Can
        role={this.state.role}
        perform="users:add"
        yes={() => (
          <Formik
            initialValues={initialValues}
            validationSchema={createUserSchema}
            onSubmit={(data) => {
                console.log(data);
                axios.post(process.env.REACT_APP_API + '/user/create/', data, this.state.config)
                  .then(res => console.log(res.data))
                  .then(setTimeout(() => { window.location = '/users/'}, 500))
                  .catch(err => alert(err.response.data))
            }}

          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <div>
                  <h3>Create New User</h3>
                  <Form>
                    <div className="form-group">
                    <label htmlFor="nickname">Username</label>
                    <Field
                      type="text"
                      name="nickname"
                      id="nickname"
                      className={
                        errors.nickname && touched.nickname ? "input-error" : null, "form-control"
                      }
                    />
                    <ErrorMessage name="nickname" component="span" className="error" />
                    </div>
                    <div className="form-group">
                    <label htmlFor="email">Email</label>
                      <Field
                        type="string"
                        name="email"
                        id="email"
                        className={
                          errors.email && touched.email ? "input-error" : null, "form-control"
                        }
                      />
                      <ErrorMessage name="email" component="span" className="error" />
                    </div>
                    <Can
                      role={this.state.role}
                      perform="users:role"
                      yes={() => (
                        <div className="form-group">
                        <label htmlFor="role">Role</label>
                          <Field
                            as="select"
                            name="role"
                            id="role"
                            className={
                              errors.role && touched.role ? "input-error" : null, "form-control"
                            }
                          >
                            {
                                this.state.roles.map(function(role) {
                                  return <option
                                    key={role}
                                    value={role}>{role}
                                    </option>;
                                })
                            }
                          </Field>
                          <ErrorMessage name="role" component="span" className="error" />
                        </div>
                      )}
                      no={() => (null)}
                    />
                    <button
                      type="submit"
                      className={!(dirty && isValid) ? "disabled-btn" : ""}
                      disabled={!dirty && isValid}
                    >
                      Submit
                    </button>
                  </Form>
                </div>
              );
            }}
          </Formik>
        )}
        no={() => (<div><h1>Whoops!</h1><h2>Your access level cannot add a user. Sorry!</h2></div>)}
        />
    )
  }
}

export default withAuth0(CreateUser);
