import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";

const AccountRow = ({ account }) => (
  <tr>
    <td>
      <Link to={`/${account._id}/social/`}>{account.accountname}</Link>
    </td>
    <td>
      {account.socialLocationId}
    </td>
    <td>
      {account.totalPosts}
    </td>
    <td>
      {account.currentMonthPosts}
    </td>
    <td>
      {account.lastMonthPosts}
    </td>
  </tr>
);

const SocialStats = ({ auth0 }) => {
  const [accounts, setAccounts] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState(null);

  const { user, getAccessTokenSilently } = auth0;
  const roleNamespace = 'https://searchboss.app/role';
  const role = user[roleNamespace];
  const org = user["https://searchboss.app/org"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };

        const response = await axios.get(`${process.env.REACT_APP_API}/social/report`, config);
        setAccounts(response.data);
        setIsReady(true);
      } catch (err) {
        console.error(err);
        setError(err.message);
        setIsReady(true);
      }
    };

    fetchData();
  }, [getAccessTokenSilently]);

  if (!isReady) {
    return <Loading />;
  }

  if (error) {
    return <div className="alert alert-danger">Error loading social stats: {error}</div>;
  }

  const totalPosts = accounts.reduce((sum, account) =>
    sum + (account.socialPosts?.length || 0), 0);

  const currentMonthPosts = accounts.reduce((sum, account) => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    return sum + (account.socialPosts?.filter(post => {
      const postDate = new Date(post.createdAt);
      return postDate.getMonth() === currentMonth &&
             postDate.getFullYear() === currentYear;
    }).length || 0);
  }, 0);

  return (
    <Can
      role={role}
      perform="orgs:stats"
      yes={() => (
        <div>
          <div className="row mb-2">
            <div className="col-lg-7">
              <h1>Social Media Stats</h1>
            </div>
            <div className="col-lg-5 text-lg-right">
              <h1>{org}</h1>
            </div>
          </div>

          <div className="row headingRow">
            <div className="col-sm-12">
              <h3>Account Details</h3>
            </div>
          </div>
          <div className="row">
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>Account Name</th>
                    <th>Location ID</th>
                    <th>Total Posts</th>
                    <th>Posts - Current Month</th>
                    <th>Posts - Last Month</th>
                  </tr>
                </thead>
                <tbody>
                  {accounts.map(account => (
                    <AccountRow key={account._id} account={account} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      no={() => (
        <div>
          <h1>WHOOPS!</h1>
          <h2>This account level does not have permission to view social stats.</h2>
        </div>
      )}
    />
  );
};

export default withAuth0(SocialStats);
