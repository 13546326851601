import React from "react";
import AuthenticationButton from "./authentication-button";
import ProfileButton from "./profile-button";

const AuthNav = () => (
  <div className="navbar-nav ml-auto">
    <AuthenticationButton />
    <ProfileButton />
  </div>
);

export default AuthNav;
