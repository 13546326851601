import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";

const Page = props => (
  <tr>
    <td>
      <Link to={"/account/"+props.account+"/page/"+props.page._id}>{props.page.url}</Link>
    </td>
    <td>
      {props.warningCount}
    </td>
    <td className="d-none d-lg-table-cell">
      {props.warnings}
    </td>
  </tr>
)

const Note = props => (
  <tr>
    <td>
      <pre>{props.note}</pre>
    </td>
    <td>
      {props.time}
    </td>
    <td className="d-none d-lg-table-cell">
      {props.user}
    </td>
  </tr>
)

class AccountHome extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];
    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      accountname: "",
      user: "",
      currentUser: user.email,
      accounturl: "",
      robots: "",
      startdate: "",
      status: "",
      boostlinks: "",
      industry: "",
      socialLocationId: "",
      socialPostCount: 0,
      contacts: "",
      excludedWords: "",
      info: "",
      pages: [],
      notes: [],
      role: role,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    }
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("accounts:view")) {
      const config = await this.config();

      axios.get(process.env.REACT_APP_API + '/account/home/' + this.props.match.params.id, config)
        .then(response => {
          console.log(response.data);
          this.setState({
            accountname: response.data.accountname,
            user: response.data.user,
            accounturl: response.data.accounturl,
            startdate: response.data.startdate,
            status: response.data.status,
            boostlinks: response.data.boostlinks,
            industry: response.data.industry,
            socialLocationId: response.data.socialLocationId,
            socialPostCount: response.data.socialPostCount,
            contacts: response.data.contacts,
            excludedWords: response.data.excludedWords,
            contentContext: response.data.contentContext,
            info: response.data.info,
            pages: response.data.pages,
            notes: response.data.notes,
            crawling: response.data.crawling,
            robots: response.data.robots,
            id: this.props.match.params.id,
            isReady: true
          })
        })
        .catch(err => console.log(err))
        .then(response => {
          document.title = this.state.accountname + " - searchboss";
        })
        .catch(function (error) {
          console.log(error);
        })
    } else {
      return null;
    }
  }

  async crawlAccount(id) {
    if (this.state.staticRules.includes("accounts:crawl")) {
      const config = await this.config();
      const data = null;

      axios.get(process.env.REACT_APP_API + '/account/' + id + '/crawling/', config)
      .then(response => {
        const date = new Date();
        const dateString = date.toDateString();

        if (response.data.crawling === false) {
          if (response.data.lastCrawl === dateString) {
            alert("On-demand crawl already completed today.")
          } else {
            axios.post(process.env.REACT_APP_API + '/account/crawl/'+id, data, config)
              .then(console.log("Crawl started!"))
              .catch((error) => {
                console.log(error);
              })

            this.setState({
              crawling: true
            })
          }
        } else {
          alert("Crawl already in progress.")
        }
      })
      .catch(err => console.log(err))
    } else {
      alert("This access level does not have permission to crawl accounts. Please contact your administrator.")
    }
  }

  pagesList() {
    return this.state.pages.map(page => {
        const warnings = [];

        if (page.h1count === 0) {
          warnings.push("No H1's.")
        }
        if (page.h1count >= 2) {
          warnings.push("Two or more H1's.")
        }
        if (page.h2count === 0) {
          warnings.push("No H2's.")
        }
        if (page.h3count <= 1) {
          warnings.push("One or less H3's.")
        }
        if (page.titleLength === undefined) {
          warnings.push("Title tag missing.")
        }
        if (page.titleLength <= 40) {
          warnings.push("Title tag too short.")
        }
        if (page.titleError === true) {
          warnings.push("Multiple title tags.")
        }
        if (page.metaDesLen === undefined) {
          warnings.push("Missing meta description.")
        }
        if (page.metaDesLen <= 50) {
          warnings.push("Meta description too short.")
        }
        if (page.metaDesLen >= 200) {
          warnings.push("Meta description too long.")
        }
        if (page.statusCode !== 200) {
          warnings.push("Status code " + page.statusCode + " found.")
        }

        if (page.robots.some(robot => robot.includes('noindex'))) {
          warnings.push("Noindexed via meta tag.")
        }

        if (page.robots.some(robot => robot.includes('nofollow'))) {
          warnings.push("Nofollowed via meta tag.")
        }

        const warningCount = warnings.length;
        var warningDetails = "";

        if ( warningCount !== 0 ) {
          warningDetails = warnings.join(" ");
          return <Page account={this.props.match.params.id} page={page} warningCount={warningCount} warnings={warningDetails} key={page._id}/>;
        }
    })
  }

  notesList() {
      var sortedNotes = this.state.notes.reverse();

      return sortedNotes.map(note => {
        return <Note note={note.note} time={note.updatedAt} user={note.user} key={note._id}/>;
      })
  }

  crawling() {
    if (this.state.crawling === true) {
      return (<h4>Crawl currently in progress.</h4>)
    } else {
      return null
    }
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    const initialValues = {
      user: this.state.currentUser,
    };

    // initialize Yup validation
    const addNoteSchema = Yup.object().shape({
      note: Yup.string().required("Note is required."),
      user: Yup.string().required("User is required."),
    });

    return (
      <Can
        role={this.state.role}
        perform="accounts:view"
        yes={() => (
          <div>
            <div className="row">
              <div className="col-lg-7">
                <h1>{this.state.accountname}</h1>
              </div>
              <div className="col-lg-5 text-lg-right">
                { this.crawling() }
              </div>
            </div>

            <div className="row">
              <div className="col-lg-7">
                <a href={ this.state.accounturl } target="_blank" rel="noreferrer">{ this.state.accounturl }</a>
              </div>
              <div className="col-lg-5 text-lg-right">
                <p><strong>User:</strong> {this.state.user}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-7">
                <p><strong>Status</strong>: { this.state.status } </p>
              </div>
              <div className="col-lg-5 text-lg-right">
                <p><strong>Start Date</strong>: { this.state.startdate }</p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-7">
                <p><strong>Contacts:</strong> {this.state.contacts}</p>
              </div>
              <div className="col-lg-5 text-lg-right table-buttons table-buttons-internal">
                <Can
                  role={this.state.role}
                  perform="accounts:edit"
                  yes={() => (<Link className="btn btn-primary" to={"../../" + this.state.id + "/edit/"}>edit</Link>)}
                  no={() => (null)}
                />

                <Can
                  role={this.state.role}
                  perform="accounts:content"
                  yes={() => (<Link className="btn btn-primary" to={"../../../" + this.state.id + "/content/"}>content</Link>)}
                  no={() => (null)}
                />

                <Can
                  role={this.state.role}
                  perform="accounts:content"
                  yes={() => (<Link className="btn btn-primary" to={"../../../" + this.state.id + "/content-existing/"}>new sites + rewrites</Link>)}
                  no={() => (null)}
                />

                <Can
                  role={this.state.role}
                  perform="accounts:boost"
                  yes={() => (<Link className="btn btn-primary" to={"../../../" + this.state.id + "/boost/"}>boost</Link>)}
                  no={() => (null)}
                />

                <Can
                  role={this.state.role}
                  perform="accounts:boost"
                  yes={() => (<Link className="btn btn-primary" to={"../../../" + this.state.id + "/social/"}>social</Link>)}
                  no={() => (null)}
                />

                <Can
                  role={this.state.role}
                  perform="accounts:productOpt"
                  yes={() => (<Link className="btn btn-primary" to={"../../../" + this.state.id + "/productOpt/"}>productOpt</Link>)}
                  no={() => (null)}
                />

                <Can
                  role={this.state.role}
                  perform="accounts:crawl"
                  yes={() => (<button className="btn btn-primary" onClick={() => { this.crawlAccount(this.state.id) }}>crawl</button>)}
                  no={() => (null)}
                />

                {this.state.status === "Sales Prospect" &&
                <Can
                  role={this.state.role}
                  perform="accounts:prospect"
                  yes={() => (<Link className="btn btn-primary" to={"../../" + this.state.id + "/prospect/"}>prospect</Link>)}
                  no={() => (null)}
                />
                }
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm-12">
                <code>
                <Can
                  role={this.state.role}
                  perform="accounts:boost"
                  yes={() => (
                    <div>
                    <span>
                    <p><strong>Account Information</strong></p><p><strong>Boost Links</strong>: { this.state.boostlinks } </p>
                    <p><strong>Boost Industry</strong>: { this.state.industry } </p>
                    </span>
                    <span>
                    <p><strong>GBP Location ID:</strong> { this.state.socialLocationId }</p>
                    <p><strong>GBP Monthly Posts:</strong> { this.state.socialPostCount }</p></span>
                    </div>
                  )}
                  no={() => (null)}
                />
                <Can
                  role={this.state.role}
                  perform="accounts:content"
                  yes={() => (
                    <span>
                    <p><strong>Excluded Words</strong>: { this.state.excludedWords } </p>
                    <p><strong>Content Context</strong>: { this.state.contentContext } </p>
                    </span>
                  )}
                  no={() => (null)}
                />
                <pre>{ this.state.info }</pre>
                </code>
              </div>
            </div>

            <div className="row headingRow">
              <div className="col-sm-12">
                <h3>Account Notes</h3>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-sm-12">
                <Formik
                  initialValues={initialValues}
                  validationSchema={addNoteSchema}
                  enableReinitialize={true}
                  onSubmit={async (values) => {
                      const config = await this.config();

                      if (this.state.crawling === false) {
                        axios.post(process.env.REACT_APP_API + '/account/note/add/' + this.props.match.params.id, values, config)
                          .then(res => console.log(res.data))
                          .then(setTimeout(() => { window.location.reload(false) }, 500))
                          .catch(err => alert(err.response.data.message))
                      } else {
                        alert("Crawling right now, try again.")
                      }
                  }}

                >
                  {(formik) => {
                    const { errors, touched, isValid, dirty } = formik;
                    return (
                      <div>
                        <Form>
                          <div className="form-group">
                          <label htmlFor="note"><h5>Create New Note</h5></label>
                          <Field
                            component="textarea"
                            name="note"
                            id="note"
                            rows="10"
                            className={
                              errors.note && touched.note ? "input-error" : null, "form-control"
                            }
                          />
                          <ErrorMessage name="note" component="span" className="error" />
                          </div>
                          <div className="form-group d-none">
                          <label htmlFor="user">User</label>
                          <Field
                            type="text"
                            name="user"
                            id="user"
                            className={
                              errors.user && touched.user ? "input-error" : null, "form-control"
                            }
                          />
                            <ErrorMessage name="user" component="span" className="error" />
                          </div>
                          <button
                            type="submit"
                            className={!(dirty && isValid) ? "disabled-btn" : ""}
                            disabled={!dirty && isValid}
                          >
                            Submit
                          </button>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            </div>
            <div className="row tableRow">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>Note</th>
                    <th>Time</th>
                    <th className="d-none d-lg-table-cell">User</th>
                  </tr>
                </thead>
                <tbody>
                  { this.notesList() }
                </tbody>
              </table>
            </div>
            <div className="row headingRow">
              <div className="col-sm-10">
                <h3>Critical Page Errors</h3>
              </div>
              <div className="col-sm-2 text-sm-right">
                <Link to={"/account/"+this.state.id+"/pages/"}>view all pages</Link>
              </div>
            </div>
            <div className="row tableRow">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>URL</th>
                    <th>Warnings</th>
                    <th className="d-none d-lg-table-cell">Details</th>
                  </tr>
                </thead>
                <tbody>
                  { this.pagesList() }
                </tbody>
              </table>
            </div>
            <div className="row headingRow">
              <div className="col-sm-12">
                <h2>Robots.txt File</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <code className="new-line">{this.state.robots}</code>
              </div>
            </div>
          </div>
        )}
        no={() => (<div><h1>WHOOPS!</h1><h2>This account level does not have permission to view account pages.</h2></div>)}
      />
    )
  }
}

export default withAuth0(AccountHome);
