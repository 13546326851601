import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";

const Account = props => (
  <tr>
    <td>
      <Link to={"/account/"+props.account._id+"/home/"}>{props.account.accountname}</Link><br />
      <small>{props.account.accounturl}</small>
    </td>
    <td>
      {props.blogCount}
    </td>
    <td>
      {props.currentMonthBlogCount}
    </td>
    <td>
      {props.lastMonthBlogCount}
    </td>
  </tr>
)

class OrgStats extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];
    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      accounts: [],
      accountname: "",
      status: "",
      boostlinks: "",
      org: user["https://searchboss.app/org"],
      role: role,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    }
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("orgs:stats")) {
      const config = await this.config();

      if (this.state.staticRules.includes("orgs:stats")) {
        const config = await this.config();

        document.title = "organizational stats - " + this.state.org + " - searchboss";

        axios.get(process.env.REACT_APP_API + '/account/', config)
          .then(response => {
            this.setState({ accounts:response.data, isReady: true });
          })
          .catch(err => console.log(err))
          .then(response => {
            let a = [...this.state.accounts];
            a.map(a => {
              if (a.crawling === true) {
                a.lastCrawl = "Crawl in progress.";
                this.setState({a});
              }
            })
          })
          .catch((error) => {
            console.log(error);
          })
      } else {
        alert("This access level does not have permission to view organizational stats. Please contact your administrator.")
      }
    } else {
      return null;
    }
  }

  contentListLength() {
    var accountsList = this.state.accounts;

    let totalPages = 0;

    for (const account of accountsList) {
      const pages = account.content.length;
      totalPages += pages;
    }

    return totalPages;
  }

  contentListLengthCurrentMonth() {
    var accountsList = this.state.accounts;

    const today = new Date();

    var month = String(today.getMonth() + 1);
    var year = String(today.getFullYear());

    if (month < 10) {
      var month = "0" + month;
    }
    let dateFilter = year + "-" + month;

    let totalPages = 0;

    for (const account of accountsList) {
      if (account.content.length > 0) {
        var contentList = account.content;

        var contentListLength = contentList.filter(function (a) {
          var x = a.createdAt.startsWith(dateFilter);
          totalPages += x;
        })
      }
    }

    return totalPages;
  }

  contentListLengthLastMonth() {
    var accountsList = this.state.accounts;

    const today = new Date();

    var month = String(today.getMonth());
    var year = String(today.getFullYear());

    if (month == 0) {
      year = year - 1;
      month = 12;
    }

    if (month < 10) {
      var month = "0" + month;
    }

    let dateFilter = year + "-" + month;

    let totalPages = 0;

    for (const account of accountsList) {
      if (account.content.length > 0) {
        var contentList = account.content;

        var contentListLength = contentList.filter(function (a) {
          var x = a.createdAt.startsWith(dateFilter);
          totalPages += x;
        })
      }
    }

    return totalPages;
  }

  spendCalculator(pages) {
    if (pages <= 500) {
      var cost = pages * 5;
      var spend = "$" + cost;
    } else if (pages >= 501 && pages <= 1000) {
      var pages = pages - 500;
      var cost = (pages * 4) + 2500;
      var spend = "$" + cost;
    } else if (pages >= 1000) {
      var pages = pages - 1000;
      var cost = (pages * 3) + 4500;
      var spend = "$" + cost;
    }

    return spend;
  }

  contentSpendCurrentMonth() {
    var pages = this.contentListLengthCurrentMonth();
    var spend = this.spendCalculator(pages);
    return spend;
  }

  contentSpendLastMonth() {
    var pages = this.contentListLengthLastMonth();
    var spend = this.spendCalculator(pages);
    return spend;
  }

  accountList(role) {
    const accountsList = this.state.accounts;

    const today = new Date();

    var month = String(today.getMonth() + 1);
    var lastMonth = String(today.getMonth());
    var year = String(today.getFullYear());

    if (month < 10) {
      var month = "0" + month;
    }

    if (lastMonth == 0) {
      lastMonth = 12;
      var lastYear = year - 1;
    }

    if (lastMonth < 10) {
      var lastMonth = "0" + lastMonth;
    }

    let currentMonthFilter = year + "-" + month;

    if ( lastYear === (year - 1) ) {
      year = lastYear
    }

    let lastMonthFilter = year + "-" + lastMonth;

    return accountsList.map(currentaccount => {
      if (this.state.staticRules.includes("accounts:boost")) {
        var blogCount = currentaccount.boostlinks;

        var currentBlogs = currentaccount.boost.filter((blog) => blog.createdAt.startsWith(currentMonthFilter));

        var currentMonthBlogCount = currentBlogs.length;

        var lastMonthBlogs = currentaccount.boost.filter((blog) => blog.createdAt.startsWith(lastMonthFilter));

        var lastMonthBlogCount = lastMonthBlogs.length;
      }

      return <Account account={currentaccount} blogCount={blogCount} currentMonthBlogCount={currentMonthBlogCount} lastMonthBlogCount={lastMonthBlogCount} role={role} key={currentaccount._id}/>;
    })
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    return (
      <Can
        role={this.state.role}
        perform="orgs:stats"
        yes={() => (
          <div>
            <div className="row mb-2">
              <div className="col-lg-7">
                <h1>Organizational Stats</h1>
              </div>
              <div className="col-lg-5 text-lg-right">
                <h1>{this.state.org}</h1>
              </div>
            </div>
            <div className="row headingRow">
              <div className="col-sm-12">
                <h3>Content Stats</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <code>
                  <b>Content Pages All Time:</b> {this.contentListLength()}<br />
                  <b>Content Pages This Month:</b> {this.contentListLengthCurrentMonth()}<br />
                  <b>Content Pages Last Month:</b> {this.contentListLengthLastMonth()}<br />
                  <b>Current Month Content Spend:</b> {this.contentSpendCurrentMonth()}<br />
                  <b>Last Month Content Spend:</b> {this.contentSpendLastMonth()}<br/>
                  <small>Content pricing $5 for first 500 pages, $4 pages 501-1000, $3 1000+</small>
                </code>
              </div>
            </div>
            <div className="row headingRow">
              <div className="col-sm-12">
                <h3>Boost Stats</h3>
              </div>
            </div>
            <div className="row">
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                        <th>Account Name</th>
                        <th>Links per Month</th>
                        <th>Created - Current Month</th>
                        <th>Created - Last Month</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.accountList(this.state.role) }
                  </tbody>
                </table>
            </div>
            </div>
            <Can
              role={this.state.role}
              perform="orgs:edit"
              yes={() => (
                <div className="row">
                  <div className="col-sm-12 text-lg-right">
                    <Link to="/org/edit" className="btn btn-primary m-3">Edit Organization</Link>
                  </div>
                </div>
              )}
              no={() => (null)}
            />
          </div>
        )}
        no={() => (<div><h1>WHOOPS!</h1><h2>This account level does not have permission to view organizational stats.</h2></div>)}
      />
    )
  }
}

export default withAuth0(OrgStats);
