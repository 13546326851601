import React, { Component } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";
import roles from "../rbac-roles";

class EditUser extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];

    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      nickname: "",
      email: "",
      role: role,
      roles: roles,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    };
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    this.setState({ config: config });

    return config;
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("users:edit")) {
      const config = await this.config();

      axios.get(process.env.REACT_APP_API + '/user/get/' + this.props.match.params.id, config)
        .then(response => {
          this.setState({
            nickname: response.data.nickname,
            email: response.data.email,
            userRole: response.data.app_metadata["role"],
            isReady: true
          })
        })
        .catch(err => console.log(err))
        .then(response => {
          document.title = this.state.nickname + " - edit user - searchboss";
        })
        .catch(function (error) {
          console.log(error);
        })
    } else {
      alert("This access level does not have permission to edit users. Please contact your administrator.")
    }
  }

  async deleteUser() {
    if (this.state.staticRules.includes("users:delete")) {
      const config = await this.config();
      const data = null;

      axios.post(process.env.REACT_APP_API + '/user/delete/' + this.props.match.params.id, data, config)
        .then(res => console.log(res.data))
        .then(setTimeout(() => { window.location = '/users/'}, 500))
        .catch(err => alert(err.response))
    } else {
      alert("This access level does not have permission to delete accounts. Please contact your administrator.")
    }
  }

    render() {
      if (this.state.isReady === false) {
        return <Loading />;
      }

      const initialValues = {
        nickname: this.state.nickname,
        email: this.state.email,
        role: this.state.userRole
      };

      // initialize Yup validation
      const editUserSchema = Yup.object().shape({
        nickname: Yup.string().required("Username is required."),
        email: Yup.string().email("Must be a valid email address").required("Email is required."),
        role: Yup.string().required("Role is required."),
        org: Yup.string().required("Organization is required."),
      });

      return (
        <Can
          role={this.state.role}
          perform="users:edit"
          yes={() => (
            <Formik
              initialValues={initialValues}
              validationSchema={editUserSchema}
              enableReinitialize={true}
              onSubmit={(data) => {
                  console.log(data);
                  axios.post(process.env.REACT_APP_API + '/user/update/'+this.props.match.params.id, data, this.state.config)
                    .then(res => console.log(res.data))
                    .then(setTimeout(() => { window.location = '/users/'}, 50))
                    .catch(err => console.log(err))
              }}

            >
              {(formik) => {
                const { errors, touched, isValid, dirty } = formik;
                return (
                  <div>
                    <h3>Edit User</h3>
                    <Form>
                      <div className="form-group">
                      <label htmlFor="nickname">Username</label>
                      <Field
                        type="text"
                        name="nickname"
                        id="nickname"
                        className={
                          errors.nickname && touched.nickname ? "input-error" : null, "form-control"
                        }
                      />
                      <ErrorMessage name="nickname" component="span" className="error" />
                      </div>
                      <div className="form-group">
                      <label htmlFor="email">Email</label>
                        <Field
                          type="string"
                          name="email"
                          id="email"
                          className={
                            errors.email && touched.email ? "input-error" : null, "form-control"
                          }
                        />
                        <ErrorMessage name="email" component="span" className="error" />
                      </div>
                      <Can
                        role={this.state.role}
                        perform="users:role"
                        yes={() => (
                          <div className="form-group">
                          <label htmlFor="role">Role</label>
                            <Field
                              as="select"
                              name="role"
                              id="role"
                              className={
                                errors.role && touched.role ? "input-error" : null, "form-control"
                              }
                            >
                              {
                                  this.state.roles.map(function(role) {
                                    return <option
                                      key={role}
                                      value={role}>{role}
                                      </option>;
                                  })
                              }
                            </Field>
                            <ErrorMessage name="role" component="span" className="error" />
                          </div>
                        )}
                        no={() => (null)}
                      />
                      <Can
                        role={this.state.role}
                        perform="orgs:edit"
                        yes={() => (
                          <div className="form-group">
                          <label htmlFor="email">Organization</label>
                            <Field
                              type="string"
                              name="org"
                              id="org"
                              className={
                                errors.org && touched.org ? "input-error" : null, "form-control"
                              }
                            />
                            <ErrorMessage name="org" component="span" className="error" />
                          </div>
                        )}
                        no={() => (null)}
                      />
                      <button
                        type="submit"
                        className={!(dirty && isValid) ? "disabled-btn" : ""}
                        disabled={!dirty && isValid}
                      >
                        Submit
                      </button>
                    </Form>

                    <Can
                      role={this.state.role}
                      perform="users:delete"
                      yes={() => (<button className="btn btn-danger mt-3 float-right" onClick={() => { this.deleteUser() }}>Delete User</button>)}
                      no={() => (null)}
                    />
                  </div>
                );
              }}
            </Formik>
          )}
        no={() => (<h4>Sorry fam, these permissions ain't gonna work.</h4>)}
      />

      )
    }
  }

export default withAuth0(EditUser);
