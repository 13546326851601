import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";
import roles from "../rbac-roles";

const Blogs = props => (
  <tr>
  <td>{props.blog.topic}</td>
  <td className="d-table-cell"><a href={props.blog.link} target="_blank">{props.blog.link}</a></td>
  <td className="d-none d-lg-table-cell">{props.blog.anchorText}</td>
  <td className="d-none d-lg-table-cell">{props.blog.updatedAt}</td>
  <td className="table-buttons">
  <Can
    role={props.role}
    perform="accounts:boostDelete"
    yes={() => (<button className="btn btn-primary" onClick={() => { props.boostLinkDelete(props.blog._id)}}>delete</button>)}
    no={() => (null)}
  />
  </td>
  </tr>
)

class AccountBoost extends Component {
  constructor(props) {
    super(props);

    this.boostLinkDelete = this.boostLinkDelete.bind(this);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];
    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      accountname: "",
      user: "",
      accounturl: "",
      boostlinks: "",
      blogs: [],
      role: role,
      roles: roles,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    }
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("accounts:boost")) {
      const config = await this.config();

      axios.get(process.env.REACT_APP_API + '/account/boost/' + this.props.match.params.id, config)
        .then(response => {
          this.setState({
            accountname: response.data.accountname,
            user: response.data.user,
            accounturl: response.data.accounturl,
            blogs: response.data.boost,
            crawling: response.data.crawling,
            boostlinks: response.data.boostlinks,
            isReady: true
          })

          document.title = this.state.accountname + " - boost interface - searchboss";

          const allBlogs = this.state.blogs;
          const today = new Date();

          var month = String(today.getMonth() + 1);
          var year = String(today.getFullYear());

          if (month < 10) {
            var month = "0" + month;
          }
          let dateFilter = year + "-" + month;

          var monthlyBlogs = allBlogs.filter((blog) => blog.createdAt.startsWith(dateFilter));

          var initBlogs = this.state.boostlinks - monthlyBlogs.length;

          if ( initBlogs < 0 ) {
            initBlogs = 0;
          }

          this.setState({
            currentMonthBlogs: monthlyBlogs.length,
            initBlogs: initBlogs
          })
        })
        .catch(function(error) {
          console.log(error);
        })
    } else {
      return null;
    }
  }

  crawling() {
    if (this.state.crawling === true) {
      return (<h4>Crawl currently in progress.</h4>)
    } else {
      return null
    }
  }

  blogList(role) {
    const blogList = this.state.blogs;

    return blogList.map(currentblog => {
      return <Blogs blog={currentblog} boostLinkDelete={this.boostLinkDelete} role={role} key={currentblog._id}/>;
    })
  }

  async boostLinkDelete(id) {
    if (this.state.staticRules.includes("accounts:boostDelete")) {
      const config = await this.config();
      const data = { boostID: id, accountID: this.props.match.params.id };

      axios.post(process.env.REACT_APP_API + '/boost/boostLinkDelete/' + id, data, config)
        .then(
          response => {
            if (response.status === 200) {
              this.setState({
                blogs: this.state.blogs.filter(el => el._id !== id)
              })
            } else {
              alert("Response code: " + response.status)
            }
        })
        .catch(err => alert(err.response.data.message))
    } else {
      alert("This access level does not have permission to delete boost links. Please contact your administrator.")
    }
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    const initialValues = {
      blogs: this.state.initBlogs
    };

    const initialValuesManualOrder = {
      blogs: 0
    };

    console.log(initialValues);

    return (
      <Can
        role={this.state.role}
        perform="accounts:boost"
        yes={() => (
          <div>
            <div className="row">
              <div className="col-lg-7">
                <h1 className="title-h1">{this.state.accountname}</h1>
              </div>
              <div className="col-lg-5 text-lg-right">
                { this.crawling() }
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7">
                <a href={ this.state.accounturl } target="_blank" rel="noreferrer">{ this.state.accounturl }</a>
              </div>
              <div className="col-lg-5 text-lg-right">
                <p><strong>User:</strong> {this.state.user}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <hr />
                  <div className="row">
                    <div className="col-lg-7">
                      <h3>Start Boost Procurement</h3>
                      <p>Links to be created: <b>{ this.state.initBlogs }</b></p>
                      <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        onSubmit={async (values) => {
                            const config = await this.config();

                            axios.post(process.env.REACT_APP_API + '/account/boost/' + this.props.match.params.id + '/request/', values, config)
                              .then(res => console.log(res.data))
                              .catch(err => alert(err.response.data))
                            alert("Boost procurement started.")
                        }}
                      >
                      {(formik) => {
                        const { errors, touched, isValid, dirty } = formik;
                        return (

                      <Form>
                        <button
                          type="submit"
                        >
                          Let 'er rip!
                        </button>
                      </Form>
                    );
                    }}
                  </Formik>
                  <hr className="d-lg-none" />
                    </div>
                    <div className="col-lg-5 text-lg-right">
                      <p>Total boost links: <strong>{ this.state.blogs.length }</strong></p>
                      <p>Current month links: <strong>{ this.state.currentMonthBlogs }</strong></p>
                      <Formik
                          initialValues={initialValuesManualOrder}

                          onSubmit={async (values) => {
                              const config = await this.config();

                              axios.post(process.env.REACT_APP_API + '/account/boost/' + this.props.match.params.id + '/request/', values, config)
                                .then(res => console.log(res.data))
                                .catch(err => alert(err.response.data))
                              alert("Manual boost procurement started.")
                          }}
                          >
                          {(formik) => {
                            const { errors, touched, isValid, dirty } = formik;
                            return (

                            <Form>
                            <label htmlFor="blogs">Order Blogs:</label>
                              <Field
                                type="number"
                                name="blogs"
                                id="blogs"
                                className={
                                  errors.blogs && touched.blogs ? "input-error" : null, "form-control"
                                }
                              />
                              <ErrorMessage name="blogs" component="span" className="error" />

                              <button
                                type="submit"
                                className="blogs"
                              >
                                Go
                              </button>
                            </Form>
                        );
                        }}
                      </Formik>

                    </div>
                  </div>
              </div>
            </div>
            <hr />
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th>Topic</th>
                      <th className="d-table-cell">Link</th>
                      <th className="d-none d-lg-table-cell">Anchor Text</th>
                      <th className="d-none d-lg-table-cell">Time</th>
                      <th className="d-table-cell">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.blogList(this.state.role) }
                  </tbody>
                </table>
              </div>
            </div>
        )}
        no={() => (<div><h1>WHOOPS!</h1><h2>This account level does not have permission to view account content pages.</h2></div>)}
      />
    )
  }
}

export default withAuth0(AccountBoost);
