const rules = {
  visitor: {
    static: []
  },
  strategist: {
    static: [
      "accounts:add",
      "accounts:view",
      "accounts:edit",
      "accounts:crawl",
      "accounts:content",
      "page:view",
      "users:view",
      "users:add"
    ]
  },
  sales: {
    static: [
      "accounts:add",
      "accounts:view",
      "accounts:crawl",
      "accounts:prospect",
      "page:view",
      "users:view"
    ]
  },
  coordinator: {
    static: [
      "accounts:add",
      "accounts:view",
      "accounts:edit",
      "accounts:crawl",
      "accounts:content",
      "page:view",
      "users:view",
      "users:add"
    ]
  },
  admin: {
    static: [
      "accounts:add",
      "accounts:view",
      "accounts:edit",
      "accounts:crawl",
      "accounts:content",
      "page:view",
      "users:view",
      "users:add",
      "users:role",
      "users:edit",
      "users:delete",
      "orgs:stats"
    ]
  },
  boostadmin: {
    static: [
      "accounts:add",
      "accounts:view",
      "accounts:edit",
      "accounts:crawl",
      "accounts:boost",
      "accounts:domains",
      "boost:domainAdd",
      "boost:domainEdit",
      "page:view",
    ]
  },
  superadmin: {
    static: [
      "accounts:add",
      "accounts:view",
      "accounts:edit",
      "accounts:delete",
      "accounts:crawl",
      "accounts:content",
      "accounts:prospect",
      "accounts:boost",
      "accounts:boostDelete",
      "accounts:domains",
      "accounts:productOpt",
      "boost:domainAdd",
      "boost:domainDelete",
      "boost:domainEdit",
      "boost:indexAudit",
      "page:view",
      "users:view",
      "users:add",
      "users:role",
      "users:edit",
      "users:delete",
      "orgs:get",
      "orgs:add",
      "orgs:delete",
      "orgs:edit",
      "orgs:stats"
    ]
  }
};

export default rules;
