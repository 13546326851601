import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";

const User = props => (
  <tr>
    <td>
      {props.user.nickname}
    </td>
    <td className="d-none d-lg-table-cell">{props.user.name}</td>
    <td className="d-none d-lg-table-cell">
      {props.user.app_metadata.role}
    </td>
    <td className="table-buttons">
      <Can
        role={props.role}
        perform="users:edit"
        yes={() => (<Link className="btn btn-primary" to={"/user/"+props.user.user_id+"/edit/"}>edit</Link>)}
        no={() => (null)}
      />
    </td>
  </tr>
)

class UsersList extends Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];

    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      accounts: [],
      role: role,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      org: user["https://searchboss.app/org"],
      isReady: false
    };
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    document.title = "accounts - searchboss";

    if (this.state.staticRules.includes("users:view")) {
      const config = await this.config();

      axios.get(process.env.REACT_APP_API + '/user/', config)
        .then(response => {
          this.setState({ users:response.data, isReady: true });
        })
        .catch(err => console.log(err))
    } else {
      alert("This access level does not have permission to view users. Please contact your administrator.")
    }
  }



  usersList(role) {
    const usersList = this.state.users;

    return usersList.map(user => {
      return <User user={user} role={role} key={user.user_id}/>;
    })
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    return (
      <div>
        <h3>{this.state.org} Users</h3>
        <div className="table-responsive">
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th>Username</th>
                <th className="d-none d-lg-table-cell">Email</th>
                <th className="d-none d-lg-table-cell">Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              { this.usersList(this.state.role) }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default withAuth0(UsersList);
