import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";
import roles from "../rbac-roles";

const Product = props => (
  <tr>
    <td className="d-table-cell content-topic-cell">{ props.product.name }</td>
    <td className="d-none d-lg-table-cell">{ props.product.menuID }</td>
    <td className="d-none d-lg-table-cell">{ props.product.ogDescription }</td>
    <td className="d-none d-lg-table-cell">{ props.product.newDescription }</td>
    <td className="d-table-cell content-topic-cell">{ props.product.createdAt }</td>
    <td className="table-buttons">
      {props.productAPI !== "Range" && (
      <Can
        role={props.role}
        perform="accounts:productOpt"
        yes={() => (<button className="btn btn-primary" onClick={() => { props.restoreProduct(props.product.menuID, props.product.ogDescription) }}>restore</button>)}
        no={() => (null)}
      />
      )}
      <Can
        role={props.role}
        perform="accounts:productOpt"
        yes={() => (<button className="btn btn-primary" onClick={() => { props.rewriteProduct(props.product.menuID) }}>rewrite</button>)}
        no={() => (null)}
      />
    </td>
  </tr>
)

class AccountProductOpt extends Component {
  constructor(props) {
    super(props);

    this.restoreProduct = this.restoreProduct.bind(this);
    this.rewriteProduct = this.rewriteProduct.bind(this);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];
    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    }

    this.state = {
      accountname: "",
      user: "",
      accounturl: "",
      excludedWords: "",
      contentContext: "",
      products: [],
      productAPI: "",
      role: role,
      roles: roles,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    }
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("accounts:productOpt")) {
      const config = await this.config();

      axios.get(process.env.REACT_APP_API + '/account/productOpt/' + this.props.match.params.id, config)
        .then(response => {
          this.setState({
            accountname: response.data.accountname,
            user: response.data.user,
            accounturl: response.data.accounturl,
            excludedWords: response.data.excludedWords,
            contentContext: response.data.contentContext,
            products: response.data.products,
            productAPI: response.data.productAPI,
            crawling: response.data.crawling,
            isReady: true
          })
        })
        .then(response => {
          document.title = this.state.accountname + " - content interface - searchboss";
        })
        .catch(function (error) {
          console.log(error);
          })
    } else {
      return null;
    }
  }

  crawling() {
    if (this.state.crawling === true) {
      return (<h4>Crawl currently in progress.</h4>)
    } else {
      return null
    }
  }

  productList(role) {
    const productList = this.state.products;

    return productList.map(product => {
      return <Product product={product} restoreProduct={this.restoreProduct} rewriteProduct={this.rewriteProduct} productAPI={this.state.productAPI} role={role} key={product._id}/>;
    })
  }

  productListLength() {
    return this.state.products.length;
  }

  productListMonth() {
    const productList = this.state.products;

    const today = new Date();

    var month = String(today.getMonth() + 1);
    var year = String(today.getFullYear());

    if (month < 10) {
      var month = "0" + month;
    }
    let dateFilter = year + "-" + month;

    var monthProducts = productList.filter(function (a) {
      return a.createdAt.startsWith(dateFilter);
    })

    return monthProducts.length;
  }

  async restoreProduct(id, ogDescription) {
    if (this.state.staticRules.includes("accounts:productOpt")) {
      const config = await this.config();
      const data = {
        ogDescription: ogDescription
      };

      axios.post(process.env.REACT_APP_API + '/account/productOpt/' + this.props.match.params.id + '/restore/' + id, data, config)
        .then(
          response => {
            alert(response.data)
          }
        )
        .catch(err => alert(err.response.data.message))


    } else {
      alert("This access level does not have permission to restore products. Please contact your administrator.")
    }
  }

  async rewriteProduct(id) {
    if (this.state.staticRules.includes("accounts:productOpt")) {
      const config = await this.config();
      const data = null;

      axios.post(process.env.REACT_APP_API + '/account/productOpt/' + this.props.match.params.id + '/rewrite/' + id, data, config)
        .then(
          response => {
            alert(response.data)
          }
        )
        .catch(err => alert(err.response.data.message))


    } else {
      alert("This access level does not have permission to rewrite products. Please contact your administrator.")
    }
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    // initialize Yup validation
    const productOptSchema = Yup.object().shape({
      productCount: Yup.number().required("How many products?").positive(),
      skipExisting: Yup.string().required()
    });

    const initialValues = {
      productCount: 0,
      skipExisting: "Yes"
    };

    return (
      <Can
        role={this.state.role}
        perform="accounts:productOpt"
        yes={() => (
          <div>
            <div className="row">
              <div className="col-lg-7">
                <h1>{this.state.accountname}</h1>
              </div>
              <div className="col-lg-5 text-lg-right">
                { this.crawling() }
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7">
                <a href={ this.state.accounturl } target="_blank" rel="noreferrer">{ this.state.accounturl }</a>
              </div>
              <div className="col-lg-5 text-lg-right">
                <p><strong>User:</strong> {this.state.user}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7">
                <p><strong>Total Products Optimized:</strong> {this.productListLength()}</p>
              </div>
              <div className="col-lg-5 text-lg-right">
                <p><strong>Products Optimized This Month:</strong> {this.productListMonth()}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <hr />
                <Formik
                  initialValues={initialValues}
                  validationSchema={productOptSchema}
                  onSubmit={async (values) => {
                      const config = await this.config();

                      axios.post(process.env.REACT_APP_API + '/account/productOpt/' + this.props.match.params.id + '/request/', values, config)
                        .then(res => console.log(res.data))
                        .catch(err => alert(err.response.data))
                      alert("Product optimization started. This might take a while.")
                  }}

                >
                  {(formik) => {
                    const { errors, touched, isValid, dirty } = formik;
                    return (
                      <div>
                        <h3>Product Optimization</h3>
                        <Form>
                          <div className="form-group">
                          <label htmlFor="productCount">Number of Products to Optimize <small>(max 5000)</small></label>
                            <Field
                              type="number"
                              max="5000"
                              min="1"
                              name="productCount"
                              id="productCount"
                              className={
                                errors.productCount && touched.productCount ? "input-error" : null, "form-control"
                              }
                            />
                            <ErrorMessage name="length" component="span" className="error" />
                        </div>
                        <div className="form-group">
                        <label htmlFor="skipExisting">Skip Existing Products</label>
                          <Field
                            as="select"
                            name="skipExisting"
                            id="skipExisting"
                            className={
                              errors.skipExisting && touched.skipExisting ? "input-error" : null, "form-control"
                            }
                          >
                          { <option key="Yes" value="Yes">Yes</option> }
                          { <option key="No" value="No">No</option> }
                          </Field>
                          <ErrorMessage name="skipExisting" component="span" className="error" />
                          </div>
                        <div className="form-group">
                        <b>Excluded Words:</b> {this.state.excludedWords}<br />
                        <b>Content Context:</b> {this.state.contentContext}<br />
                        <small>Define excluded words & content context in the Account via the Edit panel</small>
                        </div>
                          <button
                            type="submit"
                            className={!(dirty && isValid) ? "disabled-btn" : ""}
                            disabled={!dirty && isValid}
                          >
                            Submit
                          </button>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>

              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-12">
                <h3>Optimized Products</h3>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th className="d-table-cell">Name</th>
                      <th className="d-none d-lg-table-cell">Menu ID</th>
                      <th className="d-none d-lg-table-cell">OG Description</th>
                      <th className="d-none d-lg-table-cell">Description</th>
                      <th className="d-table-cell">Timestamp</th>
                      <th className="d-table-cell">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.productList(this.state.role) }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        no={() => (<div><h1>WHOOPS!</h1><h2>This account level does not have permission to view product optimization.</h2></div>)}
      />
    )
  }
}

export default withAuth0(AccountProductOpt);
