import React, { Component } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import rules from "../rbac-rules";

class CreateOrg extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];
    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      strategists: [],
      role: role,
      staticRules: staticRules,
      dynamicRules: dynamicRules
    }
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("orgs:add")) {
      const config = await this.config();
      document.title = "create organization - searchboss";
    } else {
      return null;
    }
  }

  render() {
    // initialize Yup validation
    const createOrgSchema = Yup.object().shape({
      org: Yup.string().required("Organization name is required.")
    });

    const initialValues = {
      org: ""
    };

    return (
      <Can
        role={this.state.role}
        perform="orgs:add"
        yes={() => (
          <Formik
            initialValues={initialValues}
            validationSchema={createOrgSchema}
            onSubmit={async (values) => {
                const config = await this.config();
                axios.post(process.env.REACT_APP_API + '/org/add', values, config)
                  .then(res => console.log(res.data))
                  .then(setTimeout(() => { window.location = '/'}, 500))
                  .catch(err => console.log(err))
            }}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <div>
                  <h3>Create New Organization</h3>
                  <Form>
                    <div className="form-group">
                    <label htmlFor="accountname">Organization Name</label>
                    <Field
                      type="text"
                      name="org"
                      id="org"
                      className={
                        errors.org && touched.org ? "input-error" : null, "form-control"
                      }
                    />
                    <ErrorMessage name="org" component="span" className="error" />
                    </div>
                    <button
                      type="submit"
                      className={!(dirty && isValid) ? "disabled-btn" : ""}
                      disabled={!dirty && isValid}
                    >
                      Submit
                    </button>
                  </Form>
                </div>
              );
            }}
          </Formik>
        )}
        no={() => (<div><h1>Whoops!</h1><h2>Your access level cannot add an org. Sorry!</h2></div>)}
        />
    )
  }
}

export default withAuth0(CreateOrg);
