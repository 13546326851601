import React, { Component } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";

class EditOrg extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];

    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      users: [],
      role: role,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    }
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    document.title = "edit organization - searchboss";

    if (this.state.staticRules.includes("orgs:edit")) {
      const config = await this.config();
      const data = null;

      axios.get(process.env.REACT_APP_API + '/org/info', config)
        .then(response => {
          this.setState({
            org: response.data.org,
            permissions: response.data.permissions,
            isReady: true
          })
        })
        .catch(err => console.log(err))
        .then(response => {
          document.title = "edit organization - " + this.state.org + " - searchboss";
        })
        .catch(function (error) {
          console.log(error);
        })
    } else {
      return null;
    }
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    // initialize Yup validation
    const editOrgSchema = Yup.object().shape({
      permissions: Yup.string()
    });

    const initialValues = {
      permissions: this.state.permissions
    };

    return (
      <Can
        role={this.state.role}
        perform="orgs:edit"
        yes={() => (
          <Formik
            initialValues={initialValues}
            validationSchema={editOrgSchema}
            onSubmit={async (values) => {
                const config = await this.config();
                axios.post(process.env.REACT_APP_API + '/org/update', values, config)
                  .then(res => alert(res.data))
                  .catch(err => alert(err.message))
            }}

          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <div>
                  <h3>Edit Organization</h3>
                  <Form>
                    <div className="form-group">
                    <label htmlFor="permissions">Org Permissions</label>
                    <Field
                      type="text"
                      name="permissions"
                      id="permissions"
                      placeholder="Organization permissions as a string separated by spaces"
                      className={
                        errors.permissions && touched.permissions ? "input-error" : null, "form-control"
                      }
                    />
                    <ErrorMessage name="permissions" component="span" className="error" />
                    </div>
                    <button
                      type="submit"
                      className={!(dirty && isValid) ? "disabled-btn" : ""}
                      disabled={!dirty && isValid}
                    >
                      Submit
                    </button>
                  </Form>
                </div>
              );
            }}
          </Formik>
        )}
        no={() => (<div><h1>Whoops!</h1><h2>Your access level cannot edit an organization. Sorry!</h2></div>)}
        />
    )
  }
}

export default withAuth0(EditOrg);
