import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";
import roles from "../rbac-roles";

const Posts = props => (
  <tr>
  <td className="d-none d-lg-table-cell">{props.post.post}</td>
  <td className="d-none d-lg-table-cell">{props.post.locationId}</td>
  <td className="d-none d-lg-table-cell"><a href={props.post.searchUrl} target="_blank">{props.post.searchUrl}</a></td>
  <td className="d-none d-lg-table-cell">{props.post.updatedAt}</td>
  </tr>
)

class AccountSocial extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];
    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      accountname: "",
      user: "",
      accounturl: "",
      socialPostCount: "",
      socialPosts: [],
      socialLocationId: "",
      role: role,
      roles: roles,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    }
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("accounts:boost")) {
      const config = await this.config();

      axios.get(process.env.REACT_APP_API + '/social/' + this.props.match.params.id, config)
        .then(response => {
          this.setState({
            accountname: response.data.accountname,
            user: response.data.user,
            accounturl: response.data.accounturl,
            posts: response.data.socialPosts,
            socialLocationId: response.data.socialLocationId,
            socialPostCount: response.data.socialPostCount,
            crawling: response.data.crawling,
            isReady: true
          })

          document.title = this.state.accountname + " - social interface - searchboss";

          const allPosts = this.state.posts;
          const today = new Date();

          var month = String(today.getMonth() + 1);
          var year = String(today.getFullYear());

          if (month < 10) {
            var month = "0" + month;
          }
          let dateFilter = year + "-" + month;

          var monthlyPosts = allPosts.filter((post) => post.createdAt.startsWith(dateFilter));

          var initPosts = this.state.socialPostCount - monthlyPosts.length;

          if ( initPosts < 0 ) {
            initPosts = 0;
          }

          this.setState({
            currentMonthPosts: monthlyPosts.length,
            initPosts: initPosts
          })
        })
        .catch(function(error) {
          console.log(error);
        })
    } else {
      return null;
    }
  }

  crawling() {
    if (this.state.crawling === true) {
      return (<h4>Crawl currently in progress.</h4>)
    } else {
      return null
    }
  }

  postList(role) {
    const postList = this.state.posts;

    return postList.map(currentPost => {

      return <Posts post={currentPost} role={role} key={currentPost._id}/>;
    })
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    const initialValues = {
      posts: this.state.initPosts
    };

    const initialValuesManualOrder = {
      posts: 0
    };

    return (
      <Can
        role={this.state.role}
        perform="accounts:boost"
        yes={() => (
          <div>
            <div className="row">
              <div className="col-lg-7">
                <h1 className="title-h1">{this.state.accountname}</h1>
              </div>
              <div className="col-lg-5 text-lg-right">
                { this.crawling() }
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7">
                <a href={ this.state.accounturl } target="_blank" rel="noreferrer">{ this.state.accounturl }</a>
              </div>
              <div className="col-lg-5 text-lg-right">
                <p><strong>User:</strong> {this.state.user}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <hr />
                  <div className="row">
                    <div className="col-lg-7">
                      <h3>Start Social Procurement</h3>
                      <p>Posts to be created: <b>{ this.state.initPosts }</b></p>
                      <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        onSubmit={async (values) => {
                            const config = await this.config();

                            axios.post(process.env.REACT_APP_API + '/social/' + this.props.match.params.id + '/request/', values, config)
                              .then(res => console.log(res.data))
                              .catch(err => alert(err.response.data))
                            alert("Social procurement started.")
                        }}
                      >
                      {(formik) => {
                        const { errors, touched, isValid, dirty } = formik;
                        return (

                      <Form>
                        <button
                          type="submit"
                        >
                          Let 'er rip!
                        </button>
                      </Form>
                    );
                    }}
                  </Formik>
                  <hr className="d-lg-none" />
                    </div>
                    <div className="col-lg-5 text-lg-right">
                      <p>Total posts: <strong>{ this.state.posts.length }</strong></p>
                      <p>Current month posts: <strong>{ this.state.currentMonthPosts }</strong></p>
                      <Formik
                          initialValues={initialValuesManualOrder}

                          onSubmit={async (values) => {
                              const config = await this.config();

                              axios.post(process.env.REACT_APP_API + '/social/' + this.props.match.params.id + '/request/', values, config)
                                .then(res => console.log(res.data))
                                .catch(err => alert(err.response.data))
                              alert("Manual boost procurement started.")
                          }}
                          >
                          {(formik) => {
                            const { errors, touched, isValid, dirty } = formik;
                            return (

                            <Form>
                            <label htmlFor="posts">Order Posts:</label>
                              <Field
                                type="number"
                                name="posts"
                                id="posts"
                                className={
                                  errors.posts && touched.posts ? "input-error" : null, "form-control"
                                }
                              />
                              <ErrorMessage name="posts" component="span" className="error" />

                              <button
                                type="submit"
                                className="posts"
                              >
                                Go
                              </button>
                            </Form>
                        );
                        }}
                      </Formik>

                    </div>
                  </div>
              </div>
            </div>
            <hr />
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th className="d-table-cell">Post</th>
                      <th className="d-none d-lg-table-cell">Location ID</th>
                      <th className="d-none d-lg-table-cell">Search URL</th>
                      <th className="d-none d-lg-table-cell">Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.postList(this.state.role) }
                  </tbody>
                </table>
              </div>
            </div>
        )}
        no={() => (<div><h1>WHOOPS!</h1><h2>This account level does not have permission to view account content pages.</h2></div>)}
      />
    )
  }
}

export default withAuth0(AccountSocial);
